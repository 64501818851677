import React, { useContext, useState } from 'react'
import { graphql, useStaticQuery, Link } from 'gatsby'
import { NavContext } from '../../context/nav-context-provider'
import useLockBodyScroll from '../../hooks/use-lock-body-scroll'
import { linkResolver } from '../../utils/linkResolver'

export const Navigation = () => {
  const data = useStaticQuery(graphql`
    query NavigationQuery {
      allPrismicProject {
        nodes {
          uid
        }
      }
      allPrismicNewsfeed {
        nodes {
          data {
            articles {
              category
              date
            }
          }
        }
      }
      prismicHeader {
        ...Header
      }
    }
  `)

  const items = data?.prismicHeader?.data?.navigation

  const { navOpen, toggleNav } = useContext(NavContext)
  useLockBodyScroll(navOpen)

  const isActive = e => {
    if (!e.target.classList.contains('nav__link')) {
      return
    }
    if (e.target.classList.contains('active')) {
      e.target.classList.remove('active')
      e.target.closest('.nav__list').classList.remove('active')
    } else {
      e.target.classList.add('active')
      e.target.closest('.nav__list').classList.add('active')
    }
  }

  const projects = data.allPrismicProject.nodes.length
  const newsfeed = data.allPrismicNewsfeed.nodes[0].data.articles.length

  return (
    <nav className="header__nav nav" aria-hidden={!navOpen}>
      <ul className="nav__list">
        {items.map((navItem, index) => {
          const url = linkResolver(navItem.item)
          return (
            <li key={index} className="nav__item">
              <Link
                to={url}
                className="nav__link"
                onMouseOver={isActive}
                onMouseOut={isActive}
                onClick={toggleNav}
              >
                {navItem.item.document.data.meta_navigation_title}
                {navItem.item.type === 'homepage' && (
                  <span className="nav__link-count">{projects}</span>
                )}
                {navItem.item.type === 'newsfeed' && (
                  <span className="nav__link-count">{newsfeed}</span>
                )}
              </Link>
            </li>
          )
        })}
      </ul>
    </nav>
  )
}

export default Navigation
